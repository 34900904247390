* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  font-size: 16px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  color: #B5B5B5;
  line-height: 1.2;
  background-color: #000;
  background-image: url('../img/bg.jpg');
  background-repeat: repeat-y;
  background-position: center top;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ul,
ol {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 980px;
  padding: 0 30px;
  margin: 0 auto;
}
.btn {
  display: block;
  color: #000;
  font-size: 14px;
  padding: 10px 30px;
  background-image: linear-gradient(180deg, #9DF310 0%, #9DF310 48.44%, #609110 100%);
  border-radius: 4px;
  transition: all 0.3s ease;
}
.btn:hover {
  background-image: linear-gradient(180deg, #609110 0%, #9DF310 48.44%, #9DF310 100%);
}
header {
  background-color: #000;
  border-bottom: 2px solid #A864FF;
}
header .header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}
header .header__logo {
  color: #fff;
  font-size: 18px;
}
header .header__btn {
  color: #000;
}
main {
  background-color: #000;
}
main.wrap {
  padding-top: 40px;
  padding-bottom: 40px;
}
.content > * {
  margin-bottom: 25px;
  line-height: 1.5;
}
.content a {
  color: #A864FF;
}
.content a:visited {
  color: #e1caff;
}
.content a:hover {
  text-decoration: underline;
}
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: 400;
  padding-top: 10px;
  line-height: 1.2;
}
.content h1 {
  font-size: 28px;
  padding-top: 0;
}
.content h2 {
  font-size: 24px;
}
.content h3 {
  font-size: 20px;
}
.content h4 {
  font-size: 18px;
}
.content h5,
.content h6 {
  font-size: 16px;
  font-weight: 700;
}
.content ol,
.content ul {
  padding-left: 25px;
}
.content ul.nav-list {
  list-style: none;
}
.content ul.nav-list li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}
.content ul.nav-list li:before {
  display: block;
  content: '';
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 2px;
  background-image: url('../img/star.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
}
.content table {
  width: 100%;
  border-collapse: collapse;
}
.content table tr {
  background-color: #241B28;
}
.content table tr:nth-child(even) {
  background-color: #39313D;
}
.content table td {
  padding: 12px 20px;
  border-right: 1px solid #000;
  vertical-align: top;
}
.content table td:first-child {
  font-weight: 700;
}
footer .footer__copyright {
  text-align: center;
  font-size: 12px;
  line-height: 2;
  color: #737373;
  padding-bottom: 10px;
}
#to-top {
  position: fixed;
  width: 30px;
  height: 30px;
  bottom: 30px;
  right: 30px;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}
#to-top.show {
  opacity: 1;
  visibility: visible;
}
#to-top:hover {
  opacity: 0.8;
}
@media screen and (max-width: 980px) {
  main,
  footer {
    border-left: 3px solid #fbad02;
    border-right: 3px solid #fbad02;
  }
}
@media screen and (max-width: 768px) {
  .wrap {
    padding: 0 15px;
  }
}
@media screen and (max-width: 480px) {
  .content ul,
  .content ol {
    padding-left: 0;
  }
  .content table td {
    padding: 10px;
  }
}
